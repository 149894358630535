import React, { Component, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/src/theme/app';
import { GlobalStyle, AppWrapper } from '../../containers/App/app.style';
import { ResetCSS } from '../../../../common/src/assets/css/style';
import Navbar from '../../containers/App/Navbar';
import ContentSection from '../../containers/App/Content';
import Footer from '../../containers/App/Footer';
import { DrawerProvider } from '../../../../common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../../components/seo';

class Legal extends Component {
  render() {
    const context = this.props.pageContext;

    return (
      <ThemeProvider theme={appTheme}>
        <Fragment>
          <SEO title={context.slug} />
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar
                  project={context.project}
                  menuItems={context.menuItems}
                />
              </DrawerProvider>
            </Sticky>
            <ContentSection
              content={context.content.json}
              headline={context.headline}
            />
            <Footer project={context.project} menuItems={context.menuItems} />
          </AppWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Legal;
