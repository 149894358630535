import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import ParticlesComponent from '../particles';
import Container from 'common/src/components/UI/Container';
import RichText from '../Richtext'
import Heading from 'common/src/components/Heading';
import { BannerSquareShape, BannerCircleShape } from '../app.style';

const ContentSection = ({
  SectionWrapper,
  row,
  col,
  sectionTitle,
  content,
  headline
}) => {
  return (
    <Box {...SectionWrapper}>
      <ParticlesComponent />
      <BannerSquareShape />
      <BannerCircleShape />
      <Container>
        <Box {...row}>
          <Box {...col}>
            <Heading
                  content={headline}
                  {...sectionTitle}
                />
            <RichText content={content} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

ContentSection.propTypes = {
  content: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object
};


ContentSection.defaultProps = {
  SectionWrapper: {
    as: 'section',
    pt: '80px',
    pb: '80px',
    overflow: 'hidden',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'left',
    fontSize: ['30px', '34px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: '20px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '100%', '100%'],
    mt: '-80px',
  },
};

export default ContentSection;
